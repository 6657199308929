import React from "react";
import {
  SEO,
  PageLayout,
  AnimatedSelector,
  BackgroundImages,
  MarkdownContent,
  OutboundLink,
  FishermanIcon,
  Section,
  FilteredList,
  GroupedList,
  MultipleComponentIterator,
  SlideReveal,
  BackgroundImageTeamMember,
  InternalLink,
} from "@bluefin/components";
import { Header, Button, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";
import { createTeamMemberSlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class TeamPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      bookingUrl,
      allFishermanBusinessTeam,
      allFishermanBusinessLocation,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.hibiscussalon.com/team/"}
        />
        <PageLayout hero={false} className={"team-page"}>
          <AnimatedSelector
            targetSelector={
              ".image.background::before, .image.background::after"
            }
            duration={1500}
            transitionType={"zoom"}
            transitionProps={{
              animating: { scale: 1 },
              preAnimating: { scale: 1.4 },
              serverSide: { scale: 1.4 },
            }}
            easing={"cubic-bezier(0,1,0.99,1)"}
            runPolicy={"RUN_ONCE_EVERY_VISIT"}
            animationKey={"team-hero-animation"}
          >
            <BackgroundImages
              images={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentName: "HeroElement",
              })}
              parentClassName={"hero-container"}
              imageClassName={"hero-image overlay"}
              carouselProps={{
                leftArrowIcon: "fancy-arrow-left",
                rightArrowIcon: "fancy-arrow-right",
              }}
              imageProps={{ loadStrategy: "eager" }}
            >
              <Header
                as={"h1"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "team_header",
                  defaultValue: "Team",
                })}
              />
              <MarkdownContent
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "team_description",
                  defaultValue:
                    "The ultimate destination for beauty and self-care",
                })}
              />
              <div className={"ctas-container"}>
                <Button
                  primary={true}
                  as={OutboundLink}
                  to={bookingUrl.url}
                  event={{
                    category: "Booking",
                    action: "Schedule Appointment Intent",
                  }}
                  icon={<FishermanIcon iconName={"fancy-arrow-right"} />}
                  labelPosition={"right"}
                  content={bookingUrl.title}
                />
              </div>
            </BackgroundImages>
          </AnimatedSelector>
          <Section className={"team-content-container"}>
            <FilteredList
              items={allFishermanBusinessTeam.nodes}
              itemFilterKey={"locations.name"}
              childItemsKey={"items"}
              defaultFilterValue={"All Locations"}
              displayAllFilterValue={"All Locations"}
              filterValues={allFishermanBusinessLocation.nodes}
              filterValueKey={"name"}
              filterValuesComponent={<Button />}
              className={"team-filtered-list"}
            >
              <GroupedList
                groupBy={"teams.name"}
                childItemsKey={"iterator"}
                defaultGroupName={"Our Team"}
                groupOrderOptions={{
                  defaultGroupPosition: "start",
                  orderKey: "name",
                }}
                itemComponent={
                  <MultipleComponentIterator
                    components={[
                      {
                        component: (
                          <SlideReveal
                            className={"ui card team-member"}
                            fade={true}
                            direction={"up"}
                            triggerOnce={true}
                          />
                        ),
                        propMap: { key: "_id" },
                        children: [
                          {
                            component: (
                              <BackgroundImageTeamMember
                                useModal={false}
                                as={InternalLink}
                                moreInfoIconName={"fancy-arrow-right"}
                              />
                            ),
                            propMap: {
                              "teamMember.name": "name",
                              "teamMember.role": "role",
                              "teamMember.photo": "gatsbyImage",
                              "teamMember.description": "description",
                              "teamMember.email": "email",
                              "teamMember.phone": "phone",
                              "teamMember.locations": "locations",
                              "teamMember.hours": "hours",
                              "teamMember.social": "socialMedia",
                              to: createTeamMemberSlug,
                            },
                          },
                        ],
                      },
                    ]}
                  />
                }
                groupHeaderComponent={<Header as={"h2"} />}
                groupContainerComponent={
                  <Card.Group itemsPerRow={3} className={"stylists"} />
                }
                groupDescriptionKey={"description"}
              />
            </FilteredList>
          </Section>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Team" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    bookingUrl: fishermanBusinessWebsitePage(
      title: { regex: "/Book Now|Make An Appointment/" }
      pageType: { eq: "External" }
    ) {
      url
      title
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        teams {
          _id
          name
          description
        }
        locations {
          _id
          name
        }
        show
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        name
      }
    }
  }
`;
